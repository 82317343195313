.button {
  all: unset;
  cursor: pointer;

  border-radius: var(--radius, 0.75rem);
  padding: var(--space-sm, 0.5rem) var(--space-md, 1rem);

  background-color: var(--color-bg-button);
  color: var(--color-text-button);
  width: fit-content;
}

a.button {
  display: inline-block;
}

.button:hover {
  background-color: var(--color-bg-button--hover);
}

.button[disabled] {
  opacity: 0.5;
  pointer-events: none;
}

/* Danger variant */
.button.danger {
  background-color: var(--color-error, #dc2626);
  color: white;
}

.button.danger:hover {
  background-color: var(--color-error-dark, #b91c1c);
}

.button.danger:focus {
  outline: 2px solid var(--color-error-light, #ef4444);
  outline-offset: 2px;
}
