.fieldset {
  /** Own styles */
  border: 1px solid var(--color-border);
  border-radius: var(--radius, 0.75rem);
  padding: var(--space-md);

  /* Layout child elements nicely */
  display: flex;
  flex-flow: column;
  gap: var(--space-md);
}

.legend {
  padding: 0 var(--space-sm, 0.5rem);
  font-size: var(--size-font-lg);
}

/**
 * When used as invisible element for structure only.
 * Overwrite "own styles" of .fieldset and .legend
 */
.structural {
  border: 0px solid transparent;
  padding: 0;
}
.structural > .legend {
  display: none;
}
