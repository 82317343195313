.field {
  display: flex;
  flex-flow: column nowrap;
  gap: var(--space-xs, 0.25rem);
}

.label[aria-hidden="true"] {
  /**
   * we use aria-hidden to hide both visually and from screen readers
   * that is, `hidden` props means "fully hidden" because that's our only use case so far
   */
  display: none;
}

.hint {
  margin: 0;

  color: var(--color-inactive);
  font-size: var(--size-font-sm);
}

.error {
  margin: 0;

  color: red;
}
