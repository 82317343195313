.container {
  --spinner-track-width: 2px;
  --spinner-track-color: rgb(128 128 128 / 25%);
  --spinner-indicator-color: var(--color-primary, rgb(0 0 0 / 25%));
  --spinner-speed: 2s;

  display: inline-flex;
  width: 1em;
  height: 1em;
}

.spinner {
  flex: 1 1 auto;
  height: 100%;
  width: 100%;
}

.track,
.indicator {
  fill: none;
  stroke-width: var(--spinner-track-width);
  r: calc(0.5em - var(--spinner-track-width) / 2);
  cx: 0.5em;
  cy: 0.5em;
  transform-origin: 50% 50%;
}

.tarck {
  stroke: var(--spinner-track-color);
  transform-origin: 0% 0%;
}

.indicator {
  stroke: var(--spinner-indicator-color);
  stroke-linecap: round;
  stroke-dasharray: 150% 75%;
  animation: spin var(--spinner-speed, 2s) linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
    stroke-dasharray: 0.01em, 2.75em;
  }
  50% {
    transform: rotate(450deg);
    stroke-dasharray: 1.375em, 1.375em;
  }
  100% {
    transform: rotate(1080deg);
    stroke-dasharray: 0.01em, 2.75em;
  }
}
